import { createSlice } from "@reduxjs/toolkit";

const domainSlice = createSlice({
  name: "domain",
  initialState: {
    domains: [],
    isFetching: false,
    isError: false,
  },
  reducers: {
    getDomainsStart: (state) => {
      state.isFetching = true;
    },

    getDomainsSuccess: (state, action) => {
      state.isFetching = false;
      state.domains = action.payload;
    },

    getDomainsFailure: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    addDomainsStart: (state) => {
      state.isFetching = true;
    },

    addDomainsSuccess: (state, action) => {
      state.isFetching = false;
      state.domains.unshift(action.payload);
    },

    addDomainsFailure: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    clearDomainState: (state) => {
      state.domains = [];
    },
  },
});

export const {
  getDomainsStart,
  getDomainsFailure,
  getDomainsSuccess,
  addDomainsFailure,
  addDomainsStart,
  addDomainsSuccess,
  clearDomainState,
} = domainSlice.actions;
export default domainSlice.reducer;
