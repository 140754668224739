import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="flex  justify-between px-[15%] gap-10 items-center p-5 border-t-2 ">
      <div className="flex gap-2 flex-col">
        <p className="font-bold text-[20px]">Subdomain Monitor</p>
        <p className="text-center">
          All rights reserved &copy; {new Date().getFullYear()}
        </p>
      </div>
      <div>
        <Link
          to={"/contact"}
          className="px-4 py-1  font-bold  rounded-2xl hover:bg-gray-200   text-left"
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
}

export default Footer