import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeSlack } from "../api/notificationsApi";

const Notifications = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const { isFetching } = useSelector((state) => state.notification);

  const [slack, setSlack] = useState(currentUser?.slackKey);
  const dispatch = useDispatch();

  const slackChangeHandler = (e) => {
    setSlack(e.target.value);
  };

  const addSlackHandler = (e) => {
    e.preventDefault();
    if (!slack) {
      alert("Please fill in the Slack field.");
      return;
    }

    changeSlack(dispatch, slack);
  };

  return (
    <div className="flex items-start m-10 mx-auto  justify-start flex-col ">
      <form className=" flex flex-col gap-9 items-center justify-center  p-4 border-gray-300 rounded shadow-md w-[67vw]">
        <h1 className="mr-auto font-bold text-2xl m-2">
          Notification Settings
        </h1>
        <div className="flex flex-row items-center gap-6 justify-center mr-auto w-full">
          <span className="font-bold ml-auto">Email</span>
          <input
            id="domain"
            type="text"
            disabled
            value={currentUser?.email}
            className=" p-2 rounded-2xl border border-gray-300 focus:outline-none  w-full mr-auto"
          />
        </div>

        <div className="flex items-center justify-center gap-6 w-full ">
          <div className="flex flex-row">
            <span className="font-bold mr-auto">Slack</span>
            <span className="font-bold  text-red-500 ml-1">*</span>
          </div>
          <input
            id="domain"
            type="text"
            value={slack}
            className=" p-2 rounded-2xl border border-gray-300 focus:outline-none focus:border-blue-500 w-full"
            placeholder="Eg.https://hooks.slack.com/services/qqqqqqqqqqq/vvvvvvvvvvv/pppppppppppppppppppppppp"
            onChange={(e) => slackChangeHandler(e)}
          />
        </div>
        <button
          onClick={(e) => addSlackHandler(e)}
          disabled={isFetching}
          className={`px-6 py-2 text-white font-bold ml-auto rounded-full  hover:bg-gray-700 text-left ${
            !isFetching ? "bg-black" : "bg-gray-400 "
          }`}
        >
          {!isFetching ? "Save" : "Saving..."}
        </button>
      </form>
    </div>
  );
};

export default Notifications;
