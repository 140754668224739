import axios from "axios";

import {
  changeNotificationsFailure,
  changeNotificationsStart,
  changeNotificationsSuccess,
} from "../redux/features/notificationsSlice.js";

import { baseURL, headerConfig } from "../config/apiConfig.js";

const URL = axios.create({
  baseURL: baseURL,
});

// change slack for notification
export const changeSlack = async (dispatch, slackKey) => {
  dispatch(changeNotificationsStart());
  try {
    const response = await URL.post(
      "/api/notificationChannel",
      { slackKey: slackKey },
      { headers: headerConfig }
    );
    if (response.data.success === true) {
      dispatch(changeNotificationsSuccess());
      window.alert(response.data.msg)
    } else {
      dispatch(changeNotificationsFailure())
      window.alert(response.data.msg);
    }
  } catch (error) {
    dispatch(changeNotificationsFailure());
  }
};
