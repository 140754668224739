import React from 'react'
import Hero from './Hero'
import AppImportance from './AppImportance'
import HowItWorks from './HowItWorks'
import Video from './Video'

const Home = () => {
  
  return (
    <div className='px-[15%]'>
        <Hero/>
        <HowItWorks/>
        <Video/>
        <AppImportance/>

    </div>
  )
}

export default Home