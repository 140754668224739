import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import subdomainReducer from "./features/subdomainSlice";
import domainReducer from "./features/domainSlice";
import notificationReducer from "./features/notificationsSlice";
import messageReducer from "./features/messageSlice";


export default configureStore({
  reducer: {
    auth: authReducer,
    subdomain:subdomainReducer,
    domain:domainReducer,
    notification:notificationReducer,
    message:messageReducer
  },
});
