import React, { useState } from "react";
import { findInitialSubDomains } from "../api/subdomainApi";
import { useDispatch, useSelector } from "react-redux";
import InitalSubdomains from "./initialSubdomains";
import curlImage from "../assets/curl2.png";
import threeArrwo from "../assets/threeArow.png";
function Hero() {
  const dispatch = useDispatch();
  const { subdomains, isFetching } = useSelector((state) => state.subdomain);

  const [domain, setDomain] = useState("");

  const domainChangeHandler = (e) => {
    setDomain(e.target.value);
  };

  const findSubdomains = (e) => {
    e.preventDefault();
    findInitialSubDomains(dispatch, domain);
  };

  return (
    <div className="flex items-center justify-center flex-col">
      <div className="text-center  p-32 relative">
        <h1 className="text-2xl font-bold">
          Be the 1st one to be notified for a new
        </h1>
        <h1 className="text-2xl font-bold"> subdomain and so the 1st one</h1>
        <h1 className="text-2xl font-bold"> to find vulnerability.</h1>
        <p className="text-lg mt-[15px] ">
          A free cloud based subdomain monitoring platform
        </p>
        <p className="text-lg "> that alerts you before anyone else.</p>
        <div className=" w-24 h-24 absolute left-36 bottom-10">
          <img src={curlImage} alt="" />
        </div>

        <div className=" w-24 h-24 absolute right-8 top-16">
          <img src={threeArrwo} alt="" />
        </div>
      </div>

      <form className="flex flex-row ml-72 items-center justify-center gap-4">
        <input
          type="text"
          value={domain}
          className=" p-2 round rounded-2xl border border-gray-300 focus:outline-none focus:border-blue-500  w-[300px]"
          placeholder="Eg.bugcrowd.com"
          onChange={(e) => domainChangeHandler(e)}
        />
        <button
          onClick={(e) => findSubdomains(e)}
          className=" px-4 py-2 text-white bg-gray-800 hover:bg-gray-700 text-left font-bold rounded-full "
        >
          Find Subdomain And Monitor
        </button>
      </form>

      {isFetching ? (
        <div className="m-4 text-center font-bold">Loading...</div>
      ) : (
        <InitalSubdomains subdomains={subdomains} />
      )}
    </div>
  );
}

export default Hero;
