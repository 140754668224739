import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header";
import { authenticateUser } from "./api/authApi";
import { useDispatch } from "react-redux";
import AppRoutes from "./routes";
import "./index.css";
import Footer from "./components/Footer";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    authenticateUser(dispatch);
  }, [dispatch]);

  return (
    <div>
      <div className="flex flex-col">
        <BrowserRouter>
          <div className="other-than-footer flex flex-col ">
            <Header />
            <AppRoutes />
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}
export default App;
