import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../api/messageApi";

const ContactUs = () => {
  const { isSending } = useSelector((state) => state.message);
 const [formData, setFormData] = useState({
   platform: "Subdomain Monitor",
   name: "",
   email: "",
   message: "",
 });

 const handleChange = (event) => {
   const {name,value} = event.target;
   setFormData({ ...formData, [name]: value });
 };

  const dispatch = useDispatch();

  const sendMessageHandler = (e) => {
    e.preventDefault();
      if (!formData.name) {
        alert("Please fill in the Name field.");
        return;
      }

      if (!formData.email) {
        alert("Please fill in the Email field.");
        return;
      }

      if (!formData.message) {
        alert("Please fill in the Message field.");
        return;
      }
    sendMessage(dispatch, formData);
  };

  return (
    <div className="flex items-center m-10  justify-center flex-col  ">
      <form className=" flex flex-col gap-6 items-center justify-center  p-12">
        <h1 className="mr-auto font-bold m-2 text-2xl">Contact Us</h1>
        <span className="mr-auto  m-2">We would like to hear from you</span>
        <div className="flex flex-row items-center gap-12 justify-center mr-auto">
          <span className="font-bold ml-auto">
            Name <span className="font-bold  text-red-500 ml-1">*</span>
          </span>
          <input
            id="name"
            type="text"
            name="name"
            required
            value={formData.name}
            onChange={(e) => handleChange(e)}
            className=" p-2 rounded-2xl border border-gray-300 focus:outline-none  w-[300px] ml-auto"
          />
        </div>
        <div className="flex flex-row items-center gap-12 justify-center mr-auto">
          <span className="font-bold ml-auto">
            Email <span className="font-bold  text-red-500 ml-1">*</span>
          </span>
          <input
            id="email"
            type="email"
            name="email"
            value={formData.email}
            required
            onChange={(e) => handleChange(e)}
            className=" p-2 rounded-2xl border border-gray-300 focus:outline-none  w-[300px] ml-auto"
          />
        </div>

        <div className="flex flex-row items-center gap-6 justify-center mr-auto">
          <span className="font-bold ml-auto">
            Message <span className="font-bold  text-red-500 ml-1">*</span>
          </span>
          <textarea
            id="message"
            type="text"
            name="message"
            required
            value={formData.message}
            onChange={(e) => handleChange(e)}
            className=" p-2 rounded-2xl border border-gray-300 focus:outline-none  w-[300px] ml-auto"
          />
        </div>

        <button
          onClick={(e) => sendMessageHandler(e)}
          disabled={isSending}
          className={`px-4 py-2 text-white font-bold  rounded-2xl  w-[150px]  hover:bg-gray-700 ml-auto ${
            !isSending ? "bg-black" : "bg-gray-400 "
          }`}
        >
          {!isSending ? "Submit" : "Submiting..."}
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
