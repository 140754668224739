
export let baseURL =  process.env.REACT_APP_API_BASE_URL;
export let messageUrl = process.env.REACT_APP_MESSAGE_API_URL;

export let authToken = JSON.parse(localStorage.getItem("token"));
export let headerConfig = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  "Content-Type": "application/json",
  "x-auth-token": `${authToken}`,
};
