import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentUser: null,
    isFetching: false,
    isError: false,
  };

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,

  reducers: {
    logInStart: (state) => {
      state.isFetching = true;
    },
    logInSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
    },
    logInFailure: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    clearState: (state) => {
      state.currentUser = null;
    },
  },
});

export const {
  logInFailure,
  logInSuccess,
  logInStart,
  clearState,
  
} = authSlice.actions;
export default authSlice.reducer;
