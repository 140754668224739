import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { logOUt } from "../api/authApi";
import Login from "./login";

const Header = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currentPath = window.location.pathname;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const signOut = () => {
    logOUt(navigate, dispatch);
    toggleDropdown();
  };

  return (
    <div className="flex items-center justify-between px-[15%] py-3 bg-white border-b-2">
      <div className="font-bold  text-center">
        <Link to={"/"} className="font-Roboto text-2xl ">
          Subdomain Monitor
        </Link>
      </div>

      <div>
        {currentUser ? (
          <div className="flex flex-row justify-center items-center gap-5">
            {currentPath !== "/dashboard" && (
              <Link
                to={"/dashboard"}
                className="px-4 py-1  font-bold  rounded-2xl hover:bg-gray-200   text-left"
              >
                Back to Dashboard
              </Link>
            )}

            <div className="flex justify-center items-center relative">
              <div className="w-11 h-11 bg-gray-500 rounded-full overflow-hidden cursor-pointer">
                <img
                  src={currentUser?.profilePic}
                  alt="Profile Avatar"
                  onClick={toggleDropdown}
                />
              </div>

              {isDropdownOpen && (
                <div className="absolute right-0 top-14 mt-1 w-56 bg-white border border-gray-300 rounded shadow-md p-5 flex flex-col gap-3">
                  <span className="px-4 py-2  border-b-2 text-gray-500">
                    SIGNED IN AS {currentUser?.displayName}
                  </span>
                  <Link
                    to={"/notifications"}
                    className="px-4 py-2 text-black-800 font-bold rounded-lg hover:bg-gray-300"
                    onClick={() => toggleDropdown()}
                  >
                    Notifications
                  </Link>
                  <button
                    onClick={signOut}
                    className="px-4 py-2 text-gray-800 font-bold rounded-lg hover:bg-gray-300 text-left"
                  >
                    Sign Out
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <Login />
        )}
      </div>
    </div>
  );
};

export default Header;
