import axios from "axios";
import { baseURL, headerConfig } from "../config/apiConfig.js";
import { addDomainsFailure, addDomainsStart, addDomainsSuccess, getDomainsFailure, getDomainsStart, getDomainsSuccess } from "../redux/features/domainSlice.js";

const URL = axios.create({
  baseURL: baseURL,
});


// find domains
export const findDomains = async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token"));
  dispatch(getDomainsStart());
  try {
    const response = await URL.get("api/target", {
      headers: { ...headerConfig, "x-auth-token": token },
    });
    
    if (response.data.success === false) {
      dispatch(getDomainsFailure());
      window.alert(response.data.msg);
    } else {
       const sortedData = response.data.targets
         .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      dispatch(getDomainsSuccess(sortedData));
    }
  } catch (error) {
    dispatch(getDomainsFailure());
  }
};

// add domain
export const addDomain = async (dispatch, domain) => {
  const token = JSON.parse(localStorage.getItem("token"));
  dispatch(addDomainsStart());
  try {
    const response = await URL.post(
      "api/target",
      { domain: domain },
      { headers: { ...headerConfig, "x-auth-token": token } }
    );

    if (response.data.success === false) {

      dispatch(addDomainsFailure());
      window.alert(response.data.msg);

    } else {
      console.log(response.data);
        dispatch(addDomainsSuccess(response.data.data));
        window.alert(response.data.msg);
    }
  } catch (error) {
    dispatch(addDomainsFailure());
  }
};
