import React from "react";
import { TiTick } from "react-icons/ti";
import { BiCloud } from "react-icons/bi";
import { IoIosAlarm } from "react-icons/io";

const AppImportance = () => {
  return (
    <div className="flex flex-col items-center justify-center p-5 m-20">
      <div className="text-center flex flex-col font-bold  text-2xl p-5">
        <span>Why Subdomain Monitor is the right service for you </span>
      </div>
      <span className="text-center mb-16 w-80">
        Millions of security testers and companies trust the platform.
      </span>
      <div className="flex flex-wrap gap-4 justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-3 m-3 border-gray-300 rounded-md shadow-md p-6 hover:bg-gray-100 hover:scale-110 transition duration-300">
          <div className="cursor-pointer bg-blue-200 rounded-full p-3">
            <TiTick size={20} />
          </div>
          <p className="font-bold text-[20px]">Simple Yet Powerful</p>
          <p className="w-52 text-center">
            The simplified dashboard gives you all the power that you need to
            create and monitor targets
          </p>
        </div>

        <div className="flex flex-col justify-center items-center gap-3 m-3 border-gray-300 rounded-md shadow-md p-6 hover:bg-gray-100 hover:scale-110 transition duration-300">
          <div className="cursor-pointer bg-blue-200 rounded-full p-3">
            <BiCloud size={20} />
          </div>
          <p className="font-bold text-[20px]">Cloud Based</p>
          <p className="w-52 text-center">
            The site is cloud based which means you can monitor cloud based
            targets with ease round the clock.
          </p>
        </div>

        <div className="flex flex-col justify-center items-center gap-3 m-3 border-gray-300 rounded-md shadow-md p-6 hover:bg-gray-100 hover:scale-110 transition duration-300">
          <div className="cursor-pointer bg-blue-200 rounded-full p-3">
            <IoIosAlarm size={20} />
          </div>
          <p className="font-bold text-[20px]">Never Miss Anything</p>
          <p className="w-52 text-center">
            The accurate detection platform helps you to never miss anything
            even a small change is detected.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AppImportance;
