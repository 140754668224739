import axios from "axios";

import {
  clearState,
  logInFailure,
  logInStart,
  logInSuccess,
} from "../redux/features/authSlice.js";
import { baseURL, headerConfig } from "../config/apiConfig.js";
import { clearDomainState } from "../redux/features/domainSlice.js";

const URL = axios.create({
  baseURL: baseURL,
});

// log in request
export const login = async (dispatch,navigate, token) => {
  dispatch(logInStart());
  try {
    const response = await URL.post(
      "/api/auth/google",
      { token: token },
      { headers: headerConfig }
    );

    if (response.data.success === true) {
      localStorage.setItem(
        "token",
        JSON.stringify(response.data.jwtToken)
      );
      dispatch(logInSuccess(response.data.userDetails));
      navigate('/dashboard')
    } else {
      dispatch(logInFailure());
    }
  } catch (error) {
    dispatch(logInFailure());
  }
};

//authenticate user
export const authenticateUser = async (dispatch) => {
  try {
    const response = await URL.get("/api/authCheck", { headers: headerConfig });

    if (response.data.success === true && response.data.loggedIn === true) {
      const {data} = await URL.get("/api/me", { headers: headerConfig });
      if (data.success === true){
        dispatch(logInSuccess(data.user));
      }
    } else {
      localStorage.clear();
      dispatch(clearState());
    }
  } catch (error) {}
};

// logout
export const logOUt = (navigate, dispatch) => {
  localStorage.clear();
  dispatch(clearState());
  dispatch(clearDomainState())
  navigate("/");
};
