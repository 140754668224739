import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { useSelector } from "react-redux";
import Home from "./components/Home";
import React from "react";
import Notifications from "./components/Notifications";
import ContactUs from "./components/ContactUs";

function AppRoutes() {

  const { currentUser } = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/contact" element={<ContactUs />}></Route>
      {currentUser && <Route path="/dashboard" element={<Dashboard />}></Route>}
      {currentUser && (
        <Route path="/notifications" element={<Notifications />}></Route>
      )}
    </Routes>
  );
}

export default AppRoutes;
