import axios from "axios";
import { messageUrl } from "../config/apiConfig.js";

import {
  sendMessageFailure,
  sendMessageStart,
  sendMessageSuccess,
} from "../redux/features/messageSlice.js";

const URL = axios.create({
  baseURL: messageUrl,
});


// send message
export const sendMessage = async (dispatch, data) => {

  dispatch(sendMessageStart());

  try {
    const response = await URL.post(
      "/function-email-sending",
      { ...data },
      { headers: { "Content-Type": "application/json" } }
    );

    if (response.data.success === true) {
      dispatch(sendMessageSuccess());
      window.alert(response.data.msg);
    } else {
      dispatch(sendMessageFailure());
      window.alert(response.data.msg);
    }
    
  } catch (error) {
    dispatch(sendMessageFailure());
  }
};
