import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    isFetching: false,
    isError: false,
    isUpdated:false,
  },

  reducers: {
    changeNotificationsStart: (state) => {
      state.isFetching = true;
    },
    changeNotificationsSuccess: (state) => {
      state.isFetching = false;
      state.isUpdated = true;
    },
    changeNotificationsFailure: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
  },
});

export const {
  changeNotificationsFailure,
  changeNotificationsStart,
  changeNotificationsSuccess,
} = notificationSlice.actions;

export default notificationSlice.reducer;
