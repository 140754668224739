import React, { useState } from "react";
import Collapse from "react-collapse";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import { useSelector } from "react-redux";

const ExpandableDomain = () => {
  const [itemStates, setItemStates] = useState({});
  const { domains, isFetching } = useSelector((state) => state.domain);

  const convertDataToText = (data) => {
    let text = `${data.domain} \n`;
    data.subdomains.forEach((subdomain) => {
      text += `${subdomain.subdomain} \n`;
    });

    return text;
  };

  const handleDownload = (data) => {
    const textData = convertDataToText(data);
    const blob = new Blob([textData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${data.domain}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  //toggle the collapse
  const handleToggleCollapse = (itemId) => {
    setItemStates((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  return (
    <div className="p-10 min-h-[50vh]">
      {isFetching && <div className="text-center font-bold">Loading...</div>}

      {domains.map((domain, index) => (
        <div key={index} className="">
          <div className="flex justify-start items-center py-1 px-4 m-3 gap-8 flex-row  border-b-2">
            <div
              className="cursor-pointer  rounded-full p-3"
              onClick={() => handleToggleCollapse(domain._id)}
            >
              {!!itemStates[domain._id] ? (
                <IoIosArrowDown size={20} />
              ) : (
                <IoIosArrowForward size={20} />
              )}
            </div>
            <span>{domain?.domain}</span>
            <span className="ml-auto">
              {domain?.subdomains?.length} subdomains detected
            </span>
            <span className="ml-auto">
              Created at{" "}
              {new Date(domain.createdAt).toLocaleString(undefined, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </span>
            <div
              onClick={() => handleDownload(domain)}
              className="cursor-pointer rounded-full p-3 ml-auto"
            >
              <FiDownload size={23} />
            </div>
          </div>

          <Collapse isOpened={!!itemStates[domain._id]}>
            <div>
              {[...domain.subdomains]
                .sort(
                  (a, b) => new Date(b.dateDetected) - new Date(a.dateDetected)
                )
                .map((subdomain, i) => (
                  <div
                    key={i}
                    className="flex justify-center  px-4 m-3 gap-2 ml-[20px] flex-row"
                  >
                    <span className=" w-[330px] break-all">
                      {subdomain.subdomain}
                    </span>
                    <span>
                      Detected on{" "}
                      {new Date(subdomain.dateDetected).toLocaleString(
                        undefined,
                        {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }
                      )}
                    </span>
                  </div>
                ))}
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default ExpandableDomain;
