import React from "react";

const Video = () => {
  const video =
    "https://storage.googleapis.com/subdomain-monitoring-public-video/How-it-Works.webm";

  return (
    <div className="flex flex-col m-6 justify-center items-center h-screen">
      <span className="text-center font-bold  text-2xl p-14">
        How it works? It is easy. See the video below
      </span>
      <div className="max-w-screen-lg mx-auto">
        <div>
          <video
            src={video}
            title="video"
            className=" w-[90%] h-[80vh] my-0 mx-auto  border-2 border-gray-400"
            autoPlay="autoplay"
            muted="muted"
            loop="loop"
          >
            <source src={video} type="video/webm"></source>
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Video;
