import React from "react";
import image1 from "../assets/website (1).svg";
import image2 from "../assets/list-format.svg";
import image3 from "../assets/24-by-7-hour-service.svg";
import image4 from '../assets/message.svg';

function HowItWorks() {
  return (
    <div className="flex flex-col items-center justify-center p-5 mt-16">
      <span className="text-center font-bold text-2xl p-14">
        How to get started with Subdomain Monitor
      </span>
      <div className="flex flex-wrap items-center justify-center gap-6">
        <div className="flex flex-col justify-center items-center gap-3 m-3 py-6 border-gray-300 rounded-md shadow-md p-6 hover:bg-gray-100 transform hover:scale-110 transition duration-300">
          <img className="w-20 h-20" src={image1} alt="target" />
          <p className="font-bold text-[20px]  border-b-2 border-blue-200">
            Step 1
          </p>
          <p className="w-52 text-center">Add any target for monitoring</p>
        </div>

        <div className="flex flex-col justify-center items-center gap-3 m-3 border-gray-300 rounded-md shadow-md p-6 hover:bg-gray-100 hover:scale-110 transition duration-300">
          <img src={image2} className="w-20 h-20" alt="subdomain-list" />
          <p className="font-bold text-[20px] border-b-2 border-blue-200">
            Step 2
          </p>
          <p className="w-52 text-center">
            Immediately get all list of subdomains.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-6">
        <div className="flex flex-col justify-center items-center gap-3 m-3 border-gray-300 rounded-md shadow-md p-6 hover:bg-gray-100 hover:scale-110 transition duration-300">
          <img src={image3} className="w-20 h-20" alt="start monitoring" />

          <p className="font-bold text-[20px]  border-b-2 border-blue-200">
            Step 3
          </p>
          <p className="w-52 text-center">
            Our 24/7 server continiously monitors for new subdomains
          </p>
        </div>

        <div className="flex flex-col justify-center items-center gap-3 m-3 border-gray-300 rounded-md shadow-md p-6 hover:bg-gray-100 hover:scale-110 transition duration-300">
          <img src={image4} className="w-20 h-20" alt="message" />

          <p className="font-bold text-[20px]  border-b-2 border-blue-200">
            Step 4
          </p>
          <p className="w-52 text-center">
            Get Immediately notified over email and slack when new subdomains
            are found
          </p>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
