import React from "react";
import Login from "./login";
import { useSelector } from "react-redux";

const InitalSubdomains = ({ subdomains }) => {
  const { currentUser } = useSelector((state) => state.auth);

  return (
    <div className="relative m-4 ml-10">
      {subdomains.map((subdomain, index) => (
        <div key={index}>{subdomain}</div>
      ))}

      {subdomains.length > 5 && !currentUser && (
        <div className="absolute right-[-50px] top-44 mt-1 w-[350px]  bg-white bg-opacity-90 z-10 border border-gray-300 rounded shadow-md p-5 flex flex-col gap-3">
          Sign in with Google to find all subdomains for free and start
          monitoring
          <Login />
        </div>
      )}
    </div>
  );
};

export default InitalSubdomains;
