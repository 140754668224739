import { createSlice } from "@reduxjs/toolkit";

const subdomainSlice = createSlice({
  name: "subdomain",
  initialState: {
    subdomains: [],
    isFetching: false,
    isError: false,
  },

  reducers: {
    getSubdomainsStart: (state) => {
      state.isFetching = true;
    },

    getSubdomainsSuccess: (state, action) => {
      state.isFetching = false;
      state.subdomains = action.payload.subdomains;
    },

    getSubDomainsFailure: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    clearState: (state) => {
      state.subdomains = [];
    },
  },
});

export const { getSubDomainsFailure, getSubdomainsStart, getSubdomainsSuccess,clearState } = subdomainSlice.actions;
export default subdomainSlice.reducer;
