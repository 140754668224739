import axios from "axios";
import {
  clearState,
  getSubDomainsFailure,
  getSubdomainsStart,
  getSubdomainsSuccess,
} from "../redux/features/subdomainSlice.js";
import { baseURL, headerConfig } from "../config/apiConfig.js";

const URL = axios.create({
  baseURL: baseURL,
});

// find initial subdomains
export const findInitialSubDomains = async (dispatch, domain) => {
  dispatch(clearState())
  dispatch(getSubdomainsStart());
  try {
    const response = await URL.post(
      "/api/target/public",
      { domain: domain },
      { headers: headerConfig }
    );
    if (response.data.success === false) {
      dispatch(getSubDomainsFailure());
      window.alert(response.data.msg);
    } else {
      dispatch(getSubdomainsSuccess(response.data));
    }
  } catch (error) {
    dispatch(getSubDomainsFailure());
  }
};


