import { createSlice } from "@reduxjs/toolkit";

const messageSlice = createSlice({
  name: "message",
  initialState: {
    isSending: false,
    isError: false,
  },

  reducers: {
    sendMessageStart: (state) => {
      state.isSending = true;
    },
    sendMessageSuccess: (state) => {
      state.isSending = false;
    },
    sendMessageFailure: (state) => {
      state.isSending = false;
      state.isError = true;
    },
  },
});

export const {
  sendMessageFailure,
  sendMessageStart,
  sendMessageSuccess,
} = messageSlice.actions;

export default messageSlice.reducer;
