import React, { useEffect, useState } from "react";
import ExpandableDomain from "./ExpandableDomain";
import { useDispatch } from "react-redux";
import { addDomain, findDomains } from "../api/domainApi";

const Dashboard = () => {
  const [domain, setDomain] = useState("");
  const dispatch = useDispatch();

  const domainChangeHandler = (e) => {
    setDomain(e.target.value);
  };

  const addDomainHandler = (e) => {
    e.preventDefault();
    addDomain(dispatch, domain);
  };

  useEffect(() => {
    findDomains(dispatch);
  }, [dispatch]);

  return (
    <div className="flex items-center m-16 justify-center flex-col">
      <form className=" flex flex-row gap-4 items-center justify-center">
        <input
          id="domain"
          type="text"
          value={domain}
          className=" p-2 rounded-2xl border border-gray-300 focus:outline-none focus:border-blue-500 w-[300px]"
          placeholder="Eg.hackerone.com"
          onChange={(e) => domainChangeHandler(e)}
        />
        <button
          onClick={(e) => addDomainHandler(e)}
          className="px-8 py-2 text-white font-bold  rounded-full bg-black hover:bg-gray-700 text-left"
        >
          Create Target
        </button>
      </form>

      <ExpandableDomain />
    </div>
  );
};

export default Dashboard;
