import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { login} from "../api/authApi";
import { useNavigate } from "react-router";
import { GoogleOAuthProvider } from "@react-oauth/google";

const Login = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let loginSuccessHandler = (response) => {
    login(dispatch, navigate, response.credential);
  };

  let loginFailHandler = (error) => {
    window.alert(error);
  };

  return (
    <div>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
        <GoogleLogin
          onSuccess={loginSuccessHandler}
          onError={loginFailHandler}
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default Login;
